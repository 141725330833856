@import "@design";

// TODO (ES) Make Sure we def don't need this here
// @import '@assets/scss/main.scss';

.action-btns-wrap {
  justify-content: center;
  .btn {
    flex-basis: auto;
    flex-grow: 0;
    &:first-child {
      margin-right: 10px;
    }
  }
}
